.videoContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  border: 0;
  transform: translate(-50%, -50%);
}
